@mixin phone-sizes()
  @media only screen and (min-width: 360px) and (max-width: 640px)
    @content
  @media screen and ( max-width:  1440px  ) and (min-width: 375px)
    @content
  @media only screen and (min-width: 360px) and (max-width: 767px)
    @content
  @media only screen and ( max-width: 635px ) and (min-width: 280px)
    @content
  @media only screen and (min-width: 360px)
    @content
  @media (min-width:320px)
    @content
  @media (min-width:481px)
    @content
  @media (min-width:641px)
    @content
  @media (min-width:961px)
    @content
  @media only screen and ( max-width: 2340px ) and (min-width: 1080px)
    @content
  @media only screen and (min-device-width: 320px)  and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2)
    @content
  @media only screen and (min-device-width: 320px)  and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)
    @content
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)
    @content
  @media only screen and (min-device-width: 320px)  and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2)
    @content
  @media only screen and (min-device-width: 320px)  and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)
    @content
  @media only screen and (min-device-width: 320px)  and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)
    @content
  @media only screen and (min-device-width: 375px)  and (max-device-width: 667px)  and (-webkit-min-device-pixel-ratio: 2)
    @content
  @media only screen and (min-device-width: 375px)   and (max-device-width: 667px)   and (-webkit-min-device-pixel-ratio: 2)  and (orientation: portrait)
    @content

  /* Landscape */
  @media only screen and (min-device-width: 375px)  and (max-device-width: 667px)  and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)
    @content
  @media only screen and (min-device-width: 414px)  and (max-device-width: 736px)  and (-webkit-min-device-pixel-ratio: 3)
    @content
  @media only screen and (min-device-width: 414px)   and (max-device-width: 736px)   and (-webkit-min-device-pixel-ratio: 3)  and (orientation: portrait)
    @content
  @media only screen and (min-device-width: 414px)   and (max-device-width: 736px)  and (-webkit-min-device-pixel-ratio: 3)  and (orientation: landscape)
    @content
  @media only screen and (min-device-width: 375px)  and (max-device-width: 812px)  and (-webkit-min-device-pixel-ratio: 3)
    @content
  @media only screen and (min-device-width: 375px)  and (max-device-width: 812px)  and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait)
    @content
  @media only screen  and (min-device-width: 375px)   and (max-device-width: 812px)  and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape)
    @content
