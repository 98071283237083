@import "../src/sass/util/mediaQuery"
.App
  height: 100%
  font-family: Avenir, 'Avenir LT Std','Cairo','Roboto', sans-serif
  font-weight: 400
  width: 100%
  background-color: #ffffff
.rtl
  direction: rtl
@include phone-sizes()
  .App
    height: 100%
    font-family: Avenir, 'Avenir LT Std','Cairo','Roboto', sans-serif
    font-weight: 400
    width: 100%
    background-color: #ffffff
  .rtl
    direction: rtl
